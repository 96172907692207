<template>
	<div>
		<Header :title="!isEdit ? '新增场次' : '编辑场次'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 4, xxl: 3}"
				:wrapperCol="{span: 19, xxl: 20 }" @finish="onSubmit">
				<div style="margin-top: 30px;">
					<a-form-item label="演出时间" name="showTime" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-range-picker showTime v-model:value="modelRef.showTime" :disabled="isSee" />
					</a-form-item>
					<a-form-item label="开抢时间" name="payTime" extra="不填默认为提交时间到演出场次结束时间">
						<a-range-picker v-model:value="modelRef.payTime" showTime :disabled="isSee"></a-range-picker>
					</a-form-item>
					<a-form-item label="是否启用" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-radio-group v-model:value="modelRef.isDisabled" :disabled="isSee">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
					<!-- <a-form-item label="场次定价" name="priceType" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-radio-group v-model:value="modelRef.priceType" :disabled="isSee">
							<a-radio :value="1">默认价格</a-radio>
							<a-radio :value="2">场次价格</a-radio>
						</a-radio-group>
					</a-form-item> -->

					<div>
						<a-form-item label="票档" name="tickets" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-row>
								<a-col>
									<a-button type="primary" :disabled="isSee" @click="onAddTicketLine">新增票档</a-button>
									<a-button type="info" :disabled="isSee" @click="onCopy" style="margin-left: 20px;">应用默认票档</a-button>
									<div style="margin-top: 20px;">
										<a-table rowKey="rowIndex" :pagination="false" :columns="columns" :dataSource="modelRef.tickets" :scroll="{ x: 1500 }">
											<template #bodyCell="{ column, record, index }">
												<template v-if="column.key === 'perSellTime'">
													<div v-if="record.perSellStartTime">
														<div>{{ transDateTime(record.perSellStartTime) }}</div>
														至
														<div>{{ transDateTime(record.perSellEndTime) }}</div>
													</div>
													<div v-else>--</div>
												</template>
												<template v-if="column.key === 'memberFirstBuy'">
													<div v-if="record.memberFirstBuy">
														<div>{{ transDateTime(record.memberLimitTime) }}</div>
														至
														<div>{{ transDateTime(record.memberStopTime) }}</div>
													</div>
												</template>
												<template v-if="column.key === 'action'">
													<a-dropdown :trigger="['click', 'hover']">
														<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
														</a-button>
														<template #overlay>
															<a-menu v-if="!isSee">
																<div type="primary"
																	@click="onEditOne(record, index)">
																	<a-menu-item>
																		编辑
																	</a-menu-item>
																</div>
																<div type="primary"
																	@click="onDeleteOne(record, index)">
																	<a-menu-item>
																		删除
																	</a-menu-item>
																</div>
															</a-menu>
														</template>
													</a-dropdown>
												</template>
											</template>
										</a-table>
									</div>
								</a-col>
							</a-row>
						</a-form-item>
					</div>
				</div>
				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">取消</a-button>
					</a-col>
				</a-row>
			</a-form>

			<!-- 弹窗 -->
			<a-modal style="width:850px" v-model:visible="showModal" :title="isEdit? '编辑票档': '新增票档'" @ok="onModifyModal"
				@cancel="onCancel">
				<a-spin :spinning="loading">
					<a-form ref="modalForm" scrollToFirstError :model="modelData" name="addForm" :labelCol="{ span: 6 }"
						:wrapperCol="{span: 16}">
						<a-form-item label="票档标题" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelData.title" placeholder="请输入票档标题" />
						</a-form-item>
						<a-form-item label="票档编码" name="ticketCode" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelData.ticketCode" placeholder="请输入票档编码" />
						</a-form-item>
						<a-form-item label="原价" name="facePrice" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelData.facePrice" :min="0" :precision="2" placeholder="请输入" @change="onPriceChange('facePrice')" /> 元
						</a-form-item>
						<a-form-item label="售价" name="salePrice" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="实际销售价格">
							<a-input-number v-model:value="modelData.salePrice" :min="0" :precision="2" placeholder="请输入" @change="onPriceChange('salePrice')" /> 元
						</a-form-item>
						<a-form-item label="折扣百分比" name="discount" :rules="[{ required: true, message: '必填项不允许为空' }]">
							{{ modelData.discount || 0 }}%
							<!-- <a-input-number v-model:value="modelData.discount" :min="0" :precision="2" placeholder="请输入" @change="onPriceChange('discount')" /> % -->
						</a-form-item>
						<a-form-item label="总票数" name="totalNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelData.totalNum" :disabled="modelData.id ? true : false" :min="0" :precision="0" placeholder="请输入" /> 张
						</a-form-item>
						<a-form-item label="座位数" name="seatNum" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="单张票包含的座位数">
							<a-input-number v-model:value="modelData.seatNum" :min="0" :precision="0" :disabled="modelData.id" placeholder="请输入" /> 张
						</a-form-item>
            <a-form-item label="可售票数" name="stockNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input-number v-model:value="modelData.stockNum" :min="0" :precision="0" placeholder="请输入" /> 张
            </a-form-item>
						<a-form-item label="总票数变更" name="ticketChange" v-if="modelData.id" extra="正数:在总票数基础上相加;负数:在总票数基础上相减;不填则总票数不变">
							<a-input-number v-model:value="modelData.ticketChange" :min="0" :precision="0" placeholder="请输入" /> 张
						</a-form-item>

						<a-form-item label="早鸟票开抢时间" name="time">
							<a-range-picker v-model:value="modelData.time" showTime></a-range-picker>
						</a-form-item>

						<a-form-item name="preBuyLimit" label="早鸟票限购数量" extra="不得大于演出订单限购,填0则表示以演出订单限购为准">
							<a-input-number v-model:value="modelData.preBuyLimit" :min="0" :max="preformDetail.orderLimit || Infinity	" :precision="0" placeholder="请输入"></a-input-number>
						</a-form-item>

						<a-form-item label="一卡通价格" name="memberPrice" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelData.memberPrice" :min="0" :precision="2" placeholder="请输入" /> 元
						</a-form-item>

						<a-form-item label="是否允许一卡通优先购票" name="memberFirstBuy" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-radio-group v-model:value="modelData.memberFirstBuy">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>

						<div v-if="modelData.memberFirstBuy">
							<a-form-item label="一卡通提前购票时间" name="memberLimitTime" :rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-date-picker v-model:value="modelData.memberLimitTime" showTime></a-date-picker>
							</a-form-item>

							<a-form-item name="memberFirstBuyLimit" label="一卡通提前购票限购数量" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="不得大于演出订单限购,填0则表示以演出订单限购为准">
								<a-input-number v-model:value="modelData.memberFirstBuyLimit" :min="0" :max="preformDetail.orderLimit || Infinity	" :precision="0" placeholder="请输入"></a-input-number>
							</a-form-item>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getPerformDetail, getPerformSchedulesDetail, savePerformSchedules, updatePerformSchedules, checkSchedulesDel } from '@/service/modules/perform.js';
	export default {
		components: {
			Header,
			Icon,
		},
		props: {
			isSee: {
				type: Boolean,
				default: false
			},
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {  // 演出id
				type: Number,
				default: 0
			},
			scheduleId: {  // 场次id
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				perState: '',
				preformDetail: {},
				loading: false,
				showModal: false,
				defaultTickets: [],
				deleteTicketItems: [],
				modelRef: {
					isDisabled: 0,
					tickets: []
				},
				modelData: {
					memberFirstBuy: 0,
				}, // 票档弹窗数据
				list: [],
				columns: [{
					title: '票档标题',
					dataIndex: 'title',
					width: 120,
				}, {
					title: '票档编码',
					dataIndex: 'ticketCode',
					width: 120
				}, {
					title: '定价',
					dataIndex: 'facePrice',
					width: 100,
				}, {
					title: '售价',
					dataIndex: 'salePrice',
					width: 100,
				}, {
					title: '折扣百分比(%)',
					dataIndex: 'discount',
				}, {
					title: '总票数',
					dataIndex: 'totalNum',
					width: 100,
				}, {
					title: '可售票数',
					dataIndex: 'stockNum',
					width: 100,
				}, {
					title: '座位数',
					dataIndex: 'seatNum',
					width: 120
				}, {
					title: '早鸟票购票时间',
					key: 'perSellTime'
				}, {
					title: '早鸟票限购票数',
					dataIndex: 'preBuyLimit'
				}, {
					title: '一卡通价格',
					dataIndex: 'memberPrice',
				}, {
					title: '一卡通提前购票时间',
					key: 'memberFirstBuy'
				}, {
					title: '一卡通限购票数',
					dataIndex: 'memberFirstBuyLimit'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed:'right'
				}],
			}
		},
		created() {
			this.getPerformDetail();
			if (this.scheduleId) {
				this.getPerformSchedulesDetail();
			}
			// if (this.id) {
			// 	// 回显演出默认票档
			// 	this.getPerformDetail();
			// }
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				if (this.id && this.deleteTicketItems.length) {
					try {
						this.loading = true;
						let ret = await checkSchedulesDel({
							scheduleId: this.scheduleId,
							ticketIds: this.deleteTicketItems.map(item => item.id).join(',')
						})
						this.loading = false;
						if (ret.code === 200) {
							if (ret.data) {
								this.onSend();
							} else {
								this.$message.success('已删除的票档中存在关联订单，现已恢复删除的票档数据，请重新编辑');
								this.modelRef.tickets = this.modelRef.tickets.concat(this.deleteTicketItems);
								this.deleteTicketItems = [];
							}
						}
					} catch(e) {
						this.loading = false;
					}
				} else {
					this.onSend();
				}
			},
			async onSend() {
				let postData = this.$deepClone(this.modelRef);
				if (postData.tickets.length === 0) {
					this.$message.warn('请填写票档信息');
					return;
				}
				if (postData.tickets && postData.tickets.length) {
					postData.tickets.forEach(item => {
						delete item.rowIndex;
					})
				}
				postData.showStartTime = postData.showTime[0].unix();
				postData.showEndTime = postData.showTime[1].unix();
				delete postData.showTime;
				if (postData.payTime && postData.payTime.length) {
					postData.sellStartTime = postData.payTime[0].unix();
					postData.sellEndTime = postData.payTime[1].unix();
				}
				delete postData.payTime;
				this.loading = true;
				let ret;
				if (this.isEdit && this.scheduleId) {
					ret = await updatePerformSchedules(postData);
				} else {
					postData.performId = this.id;
					ret = await savePerformSchedules(postData)
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！');
					this.onBack(true);
				}
			},
			async getPerformSchedulesDetail() {
				this.loading = true;
				let ret = await getPerformSchedulesDetail({
					id: this.scheduleId
				})
				this.loading = false;
				if (ret.code === 200) {
					// 显示场次设置的票档
					ret.data.tickets.forEach((item, index) => {
						item.rowIndex = index;
					});
					ret.data.showTime = [this.moment(ret.data.showStartTime * 1000), this.moment(ret.data.showEndTime * 1000)];
					if (ret.data.sellStartTime) {
						ret.data.payTime = [this.moment(ret.data.sellStartTime * 1000), this.moment(ret.data.sellEndTime * 1000)]
					}
					delete ret.data.createTime;
					delete ret.data.createUser;
					delete ret.data.updateTime;
					delete ret.data.updateUser;
					delete ret.data.totalTicket;
					delete ret.data.totalStock;
					this.modelRef = ret.data;
				}
			},
			async getPerformDetail() {
				let ret = await getPerformDetail({
					id:this.id
				})
				if (ret.code === 200) {
					ret.data.tickets.forEach((item, index) => {
						item.rowIndex = index;
						delete item.id;
					});
					this.defaultTickets = ret.data.tickets;
					this.preformDetail = ret.data;
				}
			},
			onSelectPerformance() {
				this.showModal = true
			},
			selectCity(value) {
				this.modelRef.city = value[1]
			},
			onCopy() {
				this.modelRef.tickets = this.$deepClone(this.defaultTickets);
			},
			onAddTicketLine() {
				this.modelData = {
					memberFirstBuy: 0
				};
				this.editIndex = -1;
				this.showModal = true;
			},
			onEditOne(record, index) {
				this.editIndex = index;
				let editData = this.$deepClone(record);
				if (editData.memberFirstBuy) {
					editData.memberLimitTime = this.moment(editData.memberLimitTime * 1000);
				}
				if (editData.perSellStartTime) {
					editData.time = [this.moment(editData.perSellStartTime * 1000), this.moment(editData.perSellEndTime * 1000)];
				}
				this.modelData = editData;
				this.showModal = true;
			},
			async onDeleteOne(item, index) {
				if (item.id) {
					try {
						this.loading = true;
						let ret = await checkSchedulesDel({
							scheduleId: this.scheduleId,
							ticketIds: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							if (ret.data) {
								this.onDelete(item,index)
							} else {
								this.$message.success('该票档已有订单关联，无法删除');
							}
						}
					} catch(e) {
						this.loading = false;
					}
				} else {
					this.onDelete(item,index)
				}
			},
			onDelete(item, index) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						if(item.id) {
							this.deleteTicketItems.push(JSON.paese(JSON.stringify(item)));
						}
						this.modelRef.tickets.splice(index, 1);
					}
				})
			},
			onPriceChange(key){
				if (key !== 'discount' && this.modelData.facePrice && this.modelData.salePrice) {
					this.modelData.discount = ((1 - (this.modelData.salePrice/this.modelData.facePrice)) * 100).toFixed(2);
				} else if (key !== 'salePrice' && this.modelData.facePrice && this.modelData.discount) {
					this.modelData.salePrice = (this.modelData.facePrice * (1 - (this.modelData.discount / 100))).toFixed(2);
				} else if (key !== 'facePrice' && this.modelData.salePrice && this.modelData.discount) {
					this.modelData.facePrice = (this.modelData.salePrice / this.modelData.discount).toFixed(2);
				}
			},
			onModifyModal() {
				this.$refs.modalForm.validate().then(()=> {
					let postData = this.$deepClone(this.modelData);
          if (postData.totalNum < postData.stockNum) {
            this.$message.error('可售票数不能大于总票数');
            return
          }
					if (postData.memberFirstBuy && postData.memberTime && postData.memberTime.length) {
						postData.memberLimitTime = postData.memberTime[0].unix();
						postData.memberStopTime = postData.memberTime[1].unix();
					} else {
						delete postData.memberLimitTime;
						delete postData.memberStopTime;
					}
					delete postData.memberTime;
					if (postData.time && postData.time.length) {
						postData.perSellStartTime = postData.time[0].unix();
						postData.perSellEndTime = postData.time[1].unix();
					} else {
						delete postData.perSellStartTime;
						delete postData.perSellEndTime;
					}
					delete postData.time;
					if (this.editIndex >= 0) {
						this.modelRef.tickets[this.editIndex] = postData;
					} else {
						postData.rowIndex = this.modelRef.tickets.length;
						this.modelRef.tickets.push(postData);
					}
					this.$refs.modalForm.resetFields();
					this.showModal = false;
				})
			},
			onCancel() {
				this.$refs.modalForm.resetFields();
				this.showModal = false;
				this.modelData = {
					memberFirstBuy: 0
				};
			},
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 20px 0 10px;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: solid 1px #eee;
	}

	.ui-action {
		display: flex;
		justify-content: space-evenly;
	}
</style>
